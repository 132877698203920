// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-a-propos-jsx": () => import("./../../../src/pages/a-propos.jsx" /* webpackChunkName: "component---src-pages-a-propos-jsx" */),
  "component---src-pages-activites-jsx": () => import("./../../../src/pages/activites.jsx" /* webpackChunkName: "component---src-pages-activites-jsx" */),
  "component---src-pages-archives-jsx": () => import("./../../../src/pages/archives.jsx" /* webpackChunkName: "component---src-pages-archives-jsx" */),
  "component---src-pages-devenir-membre-jsx": () => import("./../../../src/pages/devenir-membre.jsx" /* webpackChunkName: "component---src-pages-devenir-membre-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-premiere-ovation-jsx": () => import("./../../../src/pages/premiere-ovation.jsx" /* webpackChunkName: "component---src-pages-premiere-ovation-jsx" */),
  "component---src-pages-ressources-jsx": () => import("./../../../src/pages/ressources.jsx" /* webpackChunkName: "component---src-pages-ressources-jsx" */),
  "component---src-templates-activity-jsx": () => import("./../../../src/templates/Activity.jsx" /* webpackChunkName: "component---src-templates-activity-jsx" */)
}

